export default {
  Balance:"Balance",
  Coupons:"Coupons",
  searchState:[
    "Transport state",
    "Pending warehouse",
    "In storage",
    "To be shipped",
    "In transit",
    "Already in port",
    "Picked up",
    "Shipped",
    "Delivered",
  ],
  searchWStatus:[
    "Cargo status",
    "Awaiting storage",
    "In storage",
    "Cancelled"
  ],
  manager:"Account manager",
  time_in:"Time in storage",
  cargoState:[
    "Cargo information",
    "Destination",
    "Cargo Type",
    "Date",
    "Order NO."
  ],
  order_no:"Order NO.",
  track_no:"Tracking NO.",
  track:"Track",
  Date:"Date",
  cargo_info:"Cargo information",
  list_title:"List of goods",
  list_track:"Tracking List",
  list_header:[
    "Storage number",
    "Photos",
    "Product name",
    "Supplier",
    "Inventory time",
    "PO/FBA(number)",
    "Of boxes",
    "Warehouse address",
    "Cargo status",
    "Arrival list number",
    "Fee",
    "Total Weight",
    "Total Volume",
    "Creation time",
    "Model",
    "Remark"
  ],
  estimated_time:"Estimated Shipping Time",
  place_no:"Order/Tracking NO.",
  place_ware:"Storage Number",
  place_state:"Cargo Type",
}