export default {
  cancel_title:"Sure you want to cancel your application?",
  warehouse_number:"warehouse Number",
  cargo_type:"Model/Cargo Type",
  pro_sku:"Product SKU",
  Reset:"Reset",
  Search:"Search",
  All:"All",
  await_warehouse:"Awaiting warehouse",
  in_warehouse:"In warehouse",
  cancelled:"Cancelled",
  apply_btn:"Apply for warehouse space",

  all_invent:"All Inventory",
  invent_clarity:"Inventory clarity (today)",
  out_warehouse:"Out of warehouse",
  cost:"List of costs",
  paid:"Amount to be paid",
  breakdown:"Breakdown of expenses",
  analysis:"Estimated cost analysis",
  cost_break:"Cost breakdown",
  stock_list:"Stock List",
  warehouse_number:"warehouse number",
  Photos:"Photos",
  case_size:"Case size",
  qty:"Carton qty",
  po_fba:"PO/FBA(number)",
  Model:"Model",
  available:"Available Quantity(ctn)",
  in_tran:"In transit(ctn)",
  to_released:"To be released(ctn)",
  in_production:"In production(ctn)",
  inventory_time:"Inventory time",
  fee:"Fee($)",
  warehouse_address:"Warehouse Address",
  action:"Action",
  total_weight:"Total weight",
  total_volume:"Total volume",
  total_num:"Total num",
  inventory:"Inventory data",
  quantities:"Quantities",
  volumetric:"Volumetric",
  apply_title:"Apply for storage space",
  outbound:"Outbound Service",
  label_qty:"Label Carton qty",
  inspect_qty:"Inspection Carton qty",
  cargo_type:"Cargo Type",
  cargo_status:"Cargo Status",
  of_boxes:"Of Boxes",
  tools:"Common Tools",
  over_warn:"Overtime warning",
  ware_rent:"Warehouse Rent Calculation",
  notice:"New notice",
  time_in:"Time in storage",
  apply_title:"Sure you want to cancel your application?",
  Cancel:"Cancel",
  Save:"Save",
  storage_number:"Storage Number",
  outbound_number:"Outbound Number",
  product_name:"Product Name",
  Supplier:"Supplier",
  apply_ok:"Apply for storage space",
  All:"All",
  in_warehouse:"In warehouse",
  await_charge:"Awaiting discharge",
  Cancelled:"Cancelled",
  Reset:"Reset",
  Search:"Search",
  export_list:"Export List",
  creation_time:"Creation time",
  arr_list:"Arrival list number",
  remark:"Remark",
  booking:"Booking",
  fill_title:"Fill in the e-mail/whatsapp and you can quickly see the information about the goods on our website!",
  email:"Enter your email/Whatsapp",
  ingo_title:"You will need to provide the inbound order number below as well as the barcode to the supplier to affix to the box.",
  Download:"Download",
  Copy:"Copy",
  Confirm:"Confirm",
  rent_cal:"Warehouse Rent Calculation",
  warm_reminder:"Warm reminder: the trial calculation results are for reference only, the final cost of the actual situation of the goods shall prevail",
  free_days:"Free days"
}