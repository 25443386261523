import order from './order/en';
import warehouse from "./warehouse/en";
import dashboard from "./dashboard/en";
export default {
  "main_navigation": "MAIN NAVIGATION",
  "home": "home",
  "door_to_door": "door to door DDP",
  "port_to_port": "Port to Port",
  "cargo_consolidation": "Cargo consolidation",
  "order_center": "ORDER CENTER",
  "my_order": "My Order",
  "tracking": "Tracking",
  "warehouse_center": "WAREHOUSE CENTER",
  "inventory": "Inventory",
  "cargo_management": "Cargo Management",
  "warehouse_rent_calculation": "Warehouse Rent Calculation",
  "account_center": "account center",
  "my_wallet": "My Wallet",
  "my_members": "My Membere",
  "my_documents": "My Documents",
  "documents": "Documents",
  "my_bill": "My Bill",
  "my_cart":"My Cart",
  "amazon_shopify_apl": "Amazon, Shopify APl",
  "origin": "origin",
  "destination": "Destination",
  "package_data": "Package Data",
  "origin": "Origin",
  "order_no":"Order No.",
  "details":"Details",
  "order_creation": "Order creation",
  "type_of_transport":"Type of transport",
  "reset": "Reset",
  "search":"Search",
  "cargo_calculation_method":'Cargo calculation method',
  "price_shipment":"Calculate by Total Shipment",
  "price_remark":"The final price is subject to the actual measurement size.",
  "total_weight":'Total Weight',
  "total_volume":'Total Volume',
  "of_boxes":'Of Boxes',
  "clear_all":"Clear all",
  "confirm":"Confirm",
  "get_price":"Get Price",
  "history": "History",
  "recommended_city": "Recommended City",
  "recommended_price": "Recommended Price",
  "port_to_port_price": "Port to Port Price",
  "ddp_price": "Door to Door Price",
  "in_review": "In review",
  "in_process": "In process",
  "pay_now": "Pay now",
  "payment_successful":"Payment successful",
  "already_in_port":"Already in port",
  "unpaid": "Unpaid",
  "state_selection":"State selection",
  "to_be_shipped": "To be shipped",
  "to_be_received": "To be received",
  "completed": "completed",
  "in_dispute": "in dispute",
  "order":"Order/Tracking NO.",
  "canceled": "canceled",
  "pol": "Pol",
  "pod": "POD",
  "hot_routes": "Hot Routes",
  "ground_west_front": "Ground West Front",
  "south_pacific_shipping_line": "South Pacific Shipping Line",
  "africa_line": "Africa Line",
  "south_east_asia": "South East Asia",
  "australia_new_zealand_line": "Australia-New Zealand line",
  "red_sea_line": "The Red Sea Line",
  "usa_ca": "USA CA",
  "euro_mediterranean_route": "Euro-mediterranean route",
  "middle_east_india_and_pakistan": "Middle East India and Pakistan",
  "south_america": "South America",
  "carrier": "Carrier",
  "method": "Method",
  "transit_time": "Transit Time",
  "price_validity": "Price validity",
  "consolidation_and_transportation": "Consolidation and transportation",
  "volume_cbm": "Volume(CBM)",
  "number_of_suppliers": "Number of suppliers",
  "supplier_tel": "Supplier Tel.",
  "consolidation_title":"The number of suppliers refers to the number of suppliers for this consolidation, filling in the supplier's data will help you to inquire and store your goods better.(Supplier phone number/supplier information file can be uploaded as a single file)",
  "supplier_information_document": "Supplier Information Document",
  "uploading_files": "Uploading files",
  "l1_member_price": "L1 Member price",
  "booking": "Booking",
  "booking_desc":"Full in the request information",
  "load_cargo":"Load Cargo",
  "load_desc":"Waiting for goods to load",
  "payment":"Payment",
  "payment_desc":"Waiting for payment",
  "unpaid":"Unpaid",
  "paid":"Paid",
  "shipped_cargo":"Shipped",
  "shipped_desc":"Cargo tracking",
  "delivered":"Delivered",
  "invoice":"Invoice",
  "tracking":"Tracking",
  "consolidation_warehousing_process": "Consolidation Warehousing Process",
  "consolidation_desc": "Cargosoon can be responsible for the business which collect cargoes from multiple suppliers, also provide loading & unloading, quality check, labeling, and storage services.",
  "c_shipping_costs":"Check shipping costs",
  "service_prices":"Value-added service prices",
  "cargo_consolidation":"Cargo Consolidation",
  "suppliers":"Number of suppliers",
  "consolidation_tran":"Consolidation and transportation",
  "pick_up_from_multiple_suppliers": "Pick up from multiple suppliers",
  "let_goods_into_warehouse": "Let the goods into our warehouse",
  "get_cargo_prepared": "Get cargo prepared(labelled, repacked, etc.)",
  "outbound_from_warehouse": "outbound from warehouse",
  "order_tracking_no": "Order/Tracking NO.",
  "order_creation": "Order creation",
  "state_selection": "State selection",
  "type_of_transport": "Type of transport",
  "sea_shipping": "Sea Shipping",
  "air_shipping": "Air Shipping",
  "parcel": "Parcel",
  "express": "Express",
  "european_railway_ddp": "European railway DDP",
  "order_details": "Order details",
  "product_details": "Product details",
  "status_and_history": "Status and history",
  "all_inventory": "All Inventory",
  "inventory_clarity_today": "Inventory clarity (today)",
  "list_of_costs": "List of costs",
  "estimated_cost_analysis": "Estimated cost analysis",
  "apply_for_storage_space": "Apply for storage space",
  "outbound_service": "Outbound Service",
  "cargo_type": "Cargo Type",
  "total_volume_cbm": "Total Volume(CBM)",
  "inventory_time_day": "Inventory Time(day)",
  "warehouse_address": "Warehouse Address",
  "common_tools": "Common Tools",
  "new_notice": "New notice",
  "time_in_storage": "Time in storage",
  "stock_list": "Stock List",
  "storage_number": "Storage number",
  "photos": "Photos",
  "case_size_cm": "Case size (cm)",
  "carton_qty": "Carton qty",
  "total_weight": "Total Weight",
  "total_volume_cbm": "Total Volume (CBM)",
  "model": "Model",
  "available_quantity_ctn": "Available Quantity (ctn)",
  "in_transit_ctn": "In transit (ctn)",
  "to_be_released_ctn": "To be released (ctn)",
  "in_production_ctn": "In production (ctn)",
  "inventory_time": "Inventory time",
  "fee_dollars": "Fee ($)",
  "inventory_data": "Inventory data",
  "supplier": "Supplier",
  "awaiting_storage": "Awaiting storage",
  "in_storage": "In storage",
  "awaiting_discharge": "Awaiting discharge",
  "out_of_storage": "Out of storage",
  "balance": "Balance",
  "top_up": "Top up",
  "withdraw": "Withdraw",
  "payment_id": "Payment ID",
  "transaction_id": "Transaction ID",
  "payment_method": "Payment Method",
  "transaction_type": "Transaction Type",
  "amount": "Amount",
  "status": "Status",
  "trading_time": "Trading Time",
  "plan_overview": "Plan Overview",
  "membership_benefits": "Membership Benefits",
  "coupons": "Coupons",
  "document_type": "Document type",
  "document_name": "Document name",
  "order_storage_no": "Order/ Storage NO.",
  "uploaders_name": "Uploader's name",
  "date": "Date",
  "action": "Action",
  "transport_type": "Transport type",
  "invoice_number": "Invoice number",
  "invoice_date": "Invoice date",
  "order_amount": "Order amount",
  "authorized_store": "Authorized store",
  "Feedback":"Feedback",
  "Feedback_title":"Tell us your suggestion or report an issue",
  "re_email":"Reply-to email",
  "send_btn":"Send feedback",
  "read":"Mark all as read",
  "Notifications":"Notifications",
  "view_de":"View details",
  "no_date":"No notification",
  "l2_title":"Free L2 Membership Upgrade",
  "action":[
    "Profile",
    "Change Password",
    "Shipping Addresses",
    "Logout",
    "Log in",
    "Sign up"
  ],
  order_component: order,
  warehouse: warehouse,
  dashboard:dashboard,
  "current_balance": "Current Balance",
  "withdraw_method": "Withdraw Method",
  "email": "Email",
  "verification_code": "Verification Code",
  "send": "Send",
  "cancel": "Cancel",
  "submit": "Submit",
  "recharge_amount": "Recharge Amount",
  "credit_card": "Credit Card",
  "handling_charge": "handling charge",
  "wire_transfer": "Wire Transfer",
  "custom_amount": "Custom Amount",
  "payment_methodList": ['Credit Card','Account Balance','Paypal','System','Wire Transfer','Payoneer'],
  "business_typeList": ['Refund','Account TopUp','Manual Credit','Affiliate Commission','Order','Account Withdrawal'],
  "statusList": ['In Progress','Success','Failed'],
  "max_withdraw": "Max Withdraw",
  "withdrawal_fee": "Withdrawal fee",
  "paypal_fee": "Paypal fee",
  "transfer_paypal": "Transfer to Paypal",
  "member": "Member",
  "privilege": "My L1 Privilege",
  "authorized_store": "Authorized store",
  "bill_type": "Bill type",
  "product_information": "Product information",
  "L1": "Free L1",
  "L2": "Free L2",
  "unlimited_users": "Unlimited users",
  "active_plan": "Active plan",
  "upgraded": "Upgrade",
  "subscribe_now": "Subscribe now",
  "bannerList1": [
    {
      title:'10% off member price',
      desc:'L1 member price 10% off from the price when logged in',
    },{
      title:'free warehouse storage',
      desc:'We will provide you with a warehouse in china for free for 15 days.',
    },{
      title:'$80 Coupon',
      desc:'2 sheets to be used when placing an order',
    },{
      title:'Unlimited shipping quotes',
      desc:'"Always Free" Unlimited Shipping Offers',
    },{
      title:'Dedicated 24/7 customer service',
      desc:'24/7 customer service and upgraded after-sales service',
    },{
      title:'Prioritize processing and shipping',
      desc:'To prioritize processing and shipping orders for the esteemed members.',
    },{
      title:'$100 Coupon',
      desc:'5 sheets to be used when placing an order',
    },{
      title:'15% off member price',
      desc:'L2 member price 15% off from the price when logged in',
    }
  ],
  "bannerList2": [
    {
      title:'15% off member price',
      desc:'L2 member price 15% off from the price when logged in',
    },{
      title:'free warehouse storage',
      desc:'We will provide you with a warehouse in china for free for 30 days.',
    },{
      title:'$80 Coupon',
      desc:'2 sheets to be used when placing an order',
    },{
      title:'Unlimited shipping quotes',
      desc:'"Always Free" Unlimited Shipping Offers',
    },{
      title:'Dedicated 24/7 customer service',
      desc:'24/7 customer service and upgraded after-sales service',
    },{
      title:'Prioritize processing and shipping',
      desc:'To prioritize processing and shipping orders for the esteemed members.',
    },{
      title:'$100 Coupon',
      desc:'5 sheets to be used when placing an order',
    }
  ],
  "apply": "Apply",
  "official_email": "Official email address of your company",
  "business_license": "Upload the business license of your company",
  "select_file": "Select file",
  "fill_one": "Fill in one to submit",
  "exporting_files": "Exporting files",
  "all": "All",
  "upload": "Upload",
  "received": "Received",
  "all_order": "All Order",
  "default": "Default",
  "profile": "Profile",
  "account": "Account",
  "modify_email": "Modify email",
  "verify_email": "Verify email",
  "add_email": "Add email",
  "first_name": "First Name",
  "last_name": "Last Name",
  "language": "Language",
  "timezone": "Timezone",
  "enterprise_validation": "Enterprise Validation",
  "verify_business": "Verify Business",
  "verifying": "Verifying",
  "verify_success": "Verification successful",
  "verify_failed": "Verification failed, re-verify",
  "enter_verify": "Enter and verify email",
  "change": "Change",
  "change_password": "Change Password",
  "old_password": "Old Password",
  "new_password": "New Password",
  "confirm_password": "Confirm Password",
  "shipping_address": "Shipping Addresses",
  "add_address": "Add New Address",
  "change_address": "Change Address",
  "remove": "Remove",
  "tel": "Tel",
  "no_address": "No shipping address",
  "tips": "Tips",
  "confirm_remove": "Are you confirm remove?",
  "add": "Add",
  "edit": "Edit",
  "a_address":" a Address",
  "company": "Company Name",
  "recipient": "Recipient Name",
  "country": "Country",
  "state": "State or Province",
  "city": "City",
  "address1": "Address Line 1",
  "address2": "Address Line 2",
  "etc": "Apartment,suite,etc.",
  "postal_code": "Postal Code",
  "phone": "Phone Number",
  "save": "Save",
  "free_member": "Free for L1 members",
  "login": "Log in",
  "email_login": "Email Login",
  "account_login": "Account Login",
  "password": "Password",
  "password_tips": "Must be 6-18 charactor",
  "remember": "Remember me",
  "forget": "Forget the password?",
  "login_google": "Log in with Google",
  "have_account": "Don't Have An Account?",
  "signup": "Sign up",
  "create_account": "Create your account",
}