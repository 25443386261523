export default {
  cancel_title:"Sure you want to cancel your application?",
  warehouse_number:"warehouse Number",
  cargo_type:"Model/Cargo Type",
  pro_sku:"Product SKU",
  Reset:"Reset",
  Search:"Search",
  All:"All",
  await_warehouse:"等待入库",
  in_warehouse:"在库",
  cancelled:"Cancelled",
  apply_btn:"Apply for warehouse space",

  all_invent:"所有库存",
  invent_clarity:"库存清单（今天）",
  out_warehouse:"已出库",
  cost:"成本清单",
  paid:"待支付金额",
  breakdown:"费用明细",
  analysis:"预估成本分析",
  cost_break:"成本明细",
  stock_list:"库存列表",
  warehouse_number:"入库单号",
  Photos:"照片",
  case_size:"箱子尺寸",
  qty:"纸箱数量",
  po_fba:"PO/FBA",
  Model:"Model",
  available:"可用数量",
  in_tran:"在途中(ctn)",
  to_released:"待发货",
  in_production:"生产中",
  inventory_time:"库存时间",
  fee:"费用($)",
  warehouse_address:"仓库地址",
  action:"操作",
  total_qty:"总件数",
  total_weight:"总重量",
  total_volume:"总体积",
  inventory:"库存数据",
  quantities:"数量",
  volumetric:"体积",
  apply_title:"入库申请",
  outbound:"出库服务",
  label_qty:"标签纸箱数量",
  inspect_qty:"检查纸箱",
  cargo_type:"货物类型",
  of_boxes:"箱数",
  tools:"常用工具",
  over_warn:"超时提醒",
  ware_rent:"仓库租金计算",
  notice:"通知",
  time_in:"在库时间",
  apply_title:"确认取消申请吗",
  Cancel:"取消",
  Save:"保存",
  storage_number:"在仓数量",
  outbound_number:"出仓数量",
  product_name:"品名",
  Supplier:"供货商",
  apply_ok:"申请入库",
  All:"所有",
  in_warehouse:"在库",
  await_charge:"等待出库",
  Cancelled:"取消",
  Reset:"重置",
  Search:"搜索",
  cargo_status:"货物状态",
  export_list:"导出",
  creation_time:"创建时间",
  arr_list:"到货清单编号",
  remark:"备注",
  booking:"预订",
  fill_title:"填写电子邮件/whatsapp，您可以在我们的网站上快速查看商品信息！",
  email:"输入 email/Whatsapp",
  ingo_title:"您需要在下面提供入库订单号以及条形码给供应商，以便贴在箱子上。",
  Download:"下载",
  Copy:"复制",
  Confirm:"确认",
  rent_cal:"仓库租金计算",
  warm_reminder:"温馨提示：试算结果仅供参考，最终成本以货物实际情况为准",
  free_days:"免费天数",
}