export default {
  b_suc:"订舱成功",
  route:"路线",
  city: "目的城市/地区",
  code:"运输方式",
  type: "服务类型",
  custom:"关税",
  details: '订舱详情',
  booking_pro:'选择路线',
  load:"装载货物",
  load_pro:"测量并到达仓库",
  payment_pro:"付款完成并准备发货",
  shipped:"装船",
  shipped_pro:"货物跟踪",
  product:"产品",
  boxes:"箱数",
  Dimensions:"尺寸",
  add_pro:"输入正确的信息",
  add_cargo:"添加",
  weight:"重量",
  qty:"数量",
  volume:"体积",
  total_qty:"总件数",
  total_weight:"总重量",
  total_volume:"总体积",
  total_num:"总数",
  goods_pro:"选择货物类型",
  cargo_gen:"普货",
  cargo_spe:"特货",
  upload_pro:"上传",
  upload_msg:"上传/拍摄产品/装箱单照片",
  shipping_to:"目的地",
  company:"公司名",
  recipient:"收件人姓名",
  address:"地址",
  change:"修改",
  remove:"删除",
  sup_num:"供应商联系电话",
  add_ser:"增值服务",
  is_insurance:"是否买保险",
  insurance_pro:"保险",
  insurance_pro_msg:"没有保险的运输是有风险的。如果您决定不购买保险，您将对丢失或损坏的物品的任何潜在索赔负责。",
  insurance_fee:"加保险，投保3‰",
  declared:"申报价值",
  cancel:"取消",
  submit:"提交",
  ship_from:"起运地",
  location_type:"位置",
  country:"国家/地区",
  city_code:"城市/邮编",
  pickup:"需要提货",
  records:"支付记录",
  time_pay:"付款时间",
  pay_methed:"付款方式",
  amount: "数量",
  status: "状态",
  action: "事件",
  pay:"支付",
  down:"下载",
  cargo_info:"货物信息",
  cargo_type:"货物类型",
  ship_to:"运输目的地",
  dest_type:"目的地类型",
  state:"州/省",
  City:"城市",
  post_code:"邮编",
  full_name:"全称",
  phone:"手机号",
  add1:"地址1",
  add1:"地址2",
  contact_pro:"我们会在24小时内联系您",
  Confirm:"确认",
  res_suc:"预订成功",
  pick_desc:"如果你不需要提货，你可以把货物送到我们的仓库。",
  copy:"复制",
  ware_info:"仓库信息",
  ware_add:"地址: Cooperate Logistics Warehouse, 102 Building C, Hezhihe Industrial Park, No.2990 Songbai Road, Shiyan, Baoan, Shenzhen",
  ware_tel:"电话: +86-28225927",
  ware_name:"联系人: Andy.Liu",
  cancel_title:"确定取消请求吗？",
  select_add:"选择运输地址",
  add_btn:"添加",
  select_file:"选择文件",
  remark:"备注",
  down_all:"下载全部",
  file_name:"文件名",
  Uploader:"上传人",
  upload_time:"上传时间",
  operations:"操作",
  download:"下载",
  delete:"删除",
  uplaod:"上传",
  upload_acc:"支持文件类型:jpg,jpeg,png,pdf",
  no_cost:"暂无数据",
  booking_number:"订舱编号",
  invoice_number:"发票号",
  expense:"费用名称",
  u_price:"单价",
  units:"单位",
  coupon:"优惠卷",
  for_over:"订单",
  coupon_req:"每个订单可以选择一个优惠卷",
  product_price:"产品价格",
  Preferential:"优惠",
  storage:"仓储费用",
  free_date:"免费存储日期",
  upgrade_l2:"免费L2会员升级",
  l2_title:"开放L2会员可享受15%的运费折扣",
  amount:"应付金额",
  ware_no:"入库编号",
  enquiry_no:"询价单号",
  invoice_no:"发票号",
  pay_time:"支付时间",
  track_no:"物流单号",
  await_pay:"等待付款审核",
  no_pay:"目前没有付款",
  pay_now:"现在支付",
  submit_pay:"提交转账单",
  bank_code:"收款银行SWIFT代码",
  bank_add:"收款银行地址",
  bank_name:"收款银行名字",
  bank_No:"收款银行账号",
  bene_add:"收款人地址",
  bene_name:"收款人姓名",
  co_bank_info:"协同付款银行信息",
  charge:"0% 手续费",
  transter:"电汇",
  account:"账户余额",
  select_method:"选择支付方式",
  amount_pay:"应付金额",
  inbound_time:"入境时间",
  Inbound_number:"入境单号",

  tran_state:"运输类型",
  pending_ware:"等待入库",
  pick_up:"已提货",
  in_ware:"在库",
  departed:"已开船",
  arrived:"到达",
  in_delivery:"交付中",
  finished:"已完成",
  back:"返回",
  view_detail:"查看订单详情",
  view_pro_detail:"查看产品详情",
  eq:"EQ Type/Q'ty",
  supplier:"供货商",
  status_his:"状态和历史",
  track:"查询"
}