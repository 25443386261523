export default {
  b_suc:"Booking success",
  route:"Route",
  city: "Departure City",
  code:"Carrier Code",
  type: "Service Type",
  custom:"Custom Duties",
  details:"Package Details",
  booking_pro:'Select a route and book a shipment',
  load:"Load Cargo",
  load_pro:"Measured and arrived at warehouse",
  payment_pro:"Payment completed and ready for shipping",
  shipped:"Shipped",
  shipped_pro:"Cargo tracking",
  product:"Product",
  boxes:"Boxes",
  Dimensions:"Dimensions",
  add_pro:"Enter a positive value for all fields",
  add_cargo:"Add a cargo",
  total_qty:"Total qty",
  weight:"Weight",
  volume:"Volume",
  qty:"QTY",
  total_weight:"Total weight",
  total_volume:"Total volume",
  total_num:"Total num",
  goods_pro:"Select your type of goods",
  cargo_gen:"General cargo",
  cargo_spe:"Special cargo",
  upload_pro:"Upload File",
  upload_msg:"Upload / take photos of product / packing list",
  shipping_to:"Shipping To",
  company:"Company Name",
  recipient:"Recipient Name",
  address:"Address",
  change:"Change",
  remove:"Remove",
  sup_num:"Supplier contact number",
  add_ser:"Value-added Services",
  is_insurance:"Whether to buy insurance?",
  insurance_pro:"Insurance",
  insurance_pro_msg:"Shipping without insurance is risky. If you decide not to purchase insurance, you'll be responsible for any potential claims for lost or damaged items.",
  insurance_fee:"Add insurance, insure 3‰, zero deductible.",
  declared:"Declared Value",
  cancel:"Cancel",
  submit:"Submit",
  ship_from:"Where to ship from?",
  location_type:"Location Type",
  country:"Country/Region",
  city_code:"City/ZIP Code",
  pickup:"Need pickup",
  records:"Payment records",
  time_pay:"Time of payment",
  pay_methed:"Payment method",
  amount: "Amount",
  status: "Status",
  action: "Action",
  pay:"Pay now",
  down:"Download invoice",
  cargo_info:"Cargo information",
  cargo_type:"Cargo Type",
  ship_to:"Shipping to",
  dest_type:"Destination Type",
  state:"State/Province",
  City:"City",
  post_code:"Postal Code",
  full_name:"Full Name",
  phone:"Phone Number",
  add1:"Address Line 1",
  add1:"Address Line 2",
  contact_pro:"We'll contact you within 24 hours",
  Confirm:"Confirm",
  res_suc:"Reservation Success",
  pick_desc:"If you do not need to pick up the goods, you can send them to our warehouse by yourself.",
  copy:"Quick Copy",
  ware_info:"Warehouse Information",
  ware_add:"Address: Cooperate Logistics Warehouse, 102 Building C, Hezhihe Industrial Park, No.2990 Songbai Road, Shiyan, Baoan, Shenzhen",
  ware_tel:"Customer service call: +86-28225927",
  ware_name:"Name: Andy.Liu",
  cancel_title:"Sure to cancel the request? After cancellation, your request will not be saved.",
  select_add:"Select Shipping Address",
  add_btn:"Add",
  select_file:"Select file",
  remark:"Remark",
  down_all:"Download All",
  file_name:"File Name",
  Uploader:"Uploader",
  upload_time:"Upload time",
  operations:"Operations",
  download:"Download",
  delete:"Delete",

  no_cost:"No cost breakdown",
  booking_number:"Booking number",
  invoice_number:"Invoice number",
  expense:"Expense name",
  u_price:"Unit price",
  units:"Units",
  coupon:"Coupon",
  for_over:"For orders over",
  coupon_req:"You can only use one coupon per order",
  product_price:"Product Price",
  Preferential:"Preferential",
  storage:"Storage Expense",
  free_date:"Free Storage Date",
  upgrade_l2:"Free L2 Membership Upgrade",
  l2_title:"Open L2 members can enjoy 15% off freight rates",
  uplaod:"uplaod",
  upload_acc:"Acceptable file types:jpg,jpeg,png,pdf",
  ware_no:"Warehousing No.",
  enquiry_no:"Enquiry No.",
  invoice_no:"Invoice No.",
  pay_time:"Payment Time",
  track_no:"Tracking NO.",
  await_pay:"Awaiting payment review",
  no_pay:"No payment at this time",

  pay_now:"Payment Now",
  submit_pay:"Submit Transfer Slip",
  bank_code:"Beneficiary Bank SWIFT CODE",
  bank_add:"Beneficiary Bank Address",
  bank_name:"Beneficiary Bank Name",
  bank_No:"Beneficiary Bank Account Number",
  bene_add:"Beneficiary Address",
  bene_name:"Beneficiary Name",
  co_bank_info:"Co-logistics Bank information",
  charge:"0% handling charge.",
  transter:"Wire Transfer",
  account:"Account Balance",
  select_method:"Choose a payment method",
  amount_pay:"Amount payable",
  inbound_time:"Inbound time",
  Inbound_number:"Inbound Number",
  tran_state:"Transport state",
  pending_ware:"Pending warehouse",
  pick_up:"Picked up",
  in_ware:"In warehouse",
  departed:"Departed",
  arrived:"Arrived",
  in_delivery:"In delivery",
  finished:"Finished",
  back:"back",
  view_detail:"View Order Details",
  view_pro_detail:"View Product Details",
  eq:"EQ Type/Q'ty",
  supplier:"Supplier",
  status_his:"Status and history",
  track:"Track"
}