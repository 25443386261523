import order from './order/zh';
import warehouse from "./warehouse/zh";
import dashboard from "./dashboard/zh";

export default {
  "main_navigation": "导航栏",
  "home": "首页",
  "door_to_door": "DDP",
  "port_to_port": "到港价格",
  "cargo_consolidation": "集拼服务",
  "order_center": "订单中心",
  "my_order": "我的订单",
  "tracking": "货物跟踪查询",
  "warehouse_center": "仓储服务",
  "inventory": "库存",
  "cargo_management": "货物管理",
  "warehouse_rent_calculation": "仓租统计",
  "account_center": "个人中心",
  "my_wallet": "我的钱包",
  "my_member": "会员详情",
  "my_documents": "我的文档",
  "documents": "文档",
  "my_bill": "账单详情",
  "my_cart":"购物车",
  "amazon_shopify": "我的店铺",
  "origin": "始发地",
  "destination": "目的地",
  "package_data": "货物重量",
  "cargo_calculation_method":'货物计费方式',
  "price_shipment":"按总发货量计算",
  "price_remark":"最终价格以实际测量尺寸为准。",
  "total_weight":'总重量',
  "total_volume":'总体积',
  "of_boxes":'箱数',
  "clear_all":"清空",
  "confirm":"确认",
  "get_price":"查询",
  "history": "历史记录",
  "recommended_city": "推荐城市",
  "recommended_price": "推荐价格",
  "port_to_port_price": "到港价格",
  "ddp_price": "DDP价格",
  "in_review": "回顾",
  "in_process": "处理中",
  "pay_now": "支付",
  "payment_successful":"支付成功",
  "already_in_port":"已到港",
  "unpaid": "未支付",
  "to_be_shipped": "已送达",
  "to_be_received": "已签收",
  "completed": "完成",
  "in_dispute": "纠纷处理中",
  "canceled": "取消",
  "order":"单号",
  "state_selection":"状态",
  "order_creation": "创建时间",
  "type_of_transport":"运输类型",
  "origin": "来源",
  "order_no":"单号",
  "details":"详情",
  "reset": "重置",
  "search":"搜索",
  "pol": "装港地",
  "pod": "卸港地",
  "hot_routes": "热门路线",
  "ground_west_front": "西线",
  "south_pacific_shipping_line": "南太平洋航线",
  "africa_line": "非洲线",
  "south_east_asia": "东南亚",
  "australia_new_zealand_line": "澳大利亚新西兰线",
  "the_red_sea_line": "红海线",
  "usa_ca": "美国加州",
  "euro_mediterranean_route": "欧洲-地中海航线",
  "middle_east_india_pakistan": "中东，印度和巴基斯坦",
  "south_america": "南美",
  "carrier": "船公司",
  "method": "方式",
  "transit_time": "运输时效",
  "price_validity": "价格有效期",
  "consolidation_and_transportation": "集拼与运输",
  "volume_cbm": "体积",
  "number_of_suppliers": "供应商数量",
  "supplier_tel": "供应商电话",
  "supplier_information_document": "供应商信息文档",
  "uploading_files": "上传文档",
  "l1_member_price": "1级会员价格",
  "booking": "预定",
  "booking_desc":"订单信息",
  "load_cargo":"订单信息",
  "load_desc":"等待运输",
  "payment":"支付",
  "payment_desc":"等待支付",
  "unpaid":"未支付",
  "paid":"已支付",
  "shipped_cargo":"运输中",
  "shipped_desc":"货物运输中",
  "delivered":"交付",
  "invoice":"发票",
  "tracking":"物流",
  "consolidation_warehousing_process": "集拼仓储流程",
  "consolidation_desc":'我们可以负责从多个供应商那里收集货物的业务，还提供装卸、质量检查、标签和存储服务。',
  "c_shipping_costs":"查询运费",
  "service_prices":"增值服务价格",
  "cargo_consolidation":"货物集拼",
  "consolidation_tran":"集拼运输",
  "suppliers":"供应商",
  "consolidation_title":"供应商数量是指本次合并的供应商数量，填写供应商数据将有助于您更好地查询和存储您的货物。（供应商电话号码/供应商信息文件可以作为单个文件上传）",
  "Supplier Tel.":"供应商电话号码",
  "pick_up_from_multiple_suppliers": "从多个供应商处提货",
  "let_goods_into_warehouse": "让货物进入我们的仓库",
  "get_cargo_prepared": "准备好货物（贴上标签、重新包装等）",
  "outbound_from_warehouse": "从仓库出站",
  "order_tracking_no": "订单/跟踪编号",
  "order_creation": "订单创建",
  "state_selection": "国家选择",
  "type_of_transport": "运输类型",
  "sea_shipping": "海运",
  "air_shipping": "空运",
  "parcel": "小包",
  "express": "快递",
  "european_railway_ddp": "欧洲铁路 DDP",
  "order_details": "订单详细信息",
  "product_details": "产品详细信息",
  "status_and_history": "状态和历史记录",
  "all_inventory": "所有库存",
  "inventory_clarity_today": "库存明细",
  "list_of_costs": "花费明细",
  "estimated_cost_analysis": "估计成本分析",
  "apply_for_storage_space": "申请存储空间",
  "outbound_service": "出站服务",
  "cargo_type": "货物类型",
  "total_volume_cbm": "总体积",
  "inventory_time_day": "库存时间",
  "warehouse_address": "仓库地址",
  "common_tools": "常用工具",
  "new_notice": "新消息提醒",
  "time_in_storage": "存储时间",
  "stock_list": "库存清单",
  "storage_number": "存储数",
  "photos": "照片",
  "case_size_cm": "尺寸 (cm)",
  "carton_qty": "纸箱数量",
  "total_weight": "总重量",
  "total_volume_cbm": "总体积 (CBM)",
  "model": "型号",
  "available_quantity_ctn": "可用数量 (ctn)",
  "in_transit_ctn": "运输中 (ctn)",
  "to_be_released_ctn": "待发布 (ctn)",
  "in_production_ctn": "生产中 (ctn)",
  "inventory_time": "库存时间",
  "fee_usd": "费用 ($)",
  "inventory_data": "库存数据",
  "supplier": "供应商",
  "awaiting_storage": "等待存储",
  "in_storage": "存储中",
  "awaiting_discharge": "等待放货",
  "out_of_storage": "出库",
  "balance": "余额",
  "top_up": "充值",
  "withdraw": "退款",
  "payment_id": "付款编号",
  "transaction_id": "交易编号",
  "payment_method": "付款方式",
  "transaction_type": "交易类别",
  "amount": "金额",
  "status": "状态",
  "trading_time": "交易时间",
  "plan_overview": "计划概述",
  "membership_benefits": "会员福利",
  "coupons": "礼券",
  "document_type": "文件类型",
  "document_name": "文件名称",
  "order_storage_no": "订单/存储编号",
  "uploaders_name": "上传者名字",
  "date": "日期",
  "action": "事件",
  "transport_type": "运输类型",
  "invoice_number": "发票号",
  "invoice_date": "发票日期",
  "order_amount": "订货量",
  "authorized_store": "授权商店",
  "Feedback":"反馈",
  "Feedback_title":"告诉我们您的建议或报告问题",
  "re_email":"回复邮箱",
  "send_btn":"提交",
  "read":"标记为已读",
  "Notifications":"系统通知",
  "view_de":"查看详情",
  "no_date":"暂无通知",
  "l2_title":"免费升级L2会员",
  "action":[
    "账号信息",
    "修改密码",
    "地址管理",
    "退出",
    "登录",
    "注册"
  ],
  order_component: order,
  warehouse: warehouse,
  dashboard:dashboard,
  "current_balance": "当前余额",
  "withdraw_method": "退款方式",
  "email": "邮箱",
  "verification_code": "验证码",
  "send": "发送",
  "cancel": "取消",
  "submit": "提交",
  "recharge_amount": "充值金额",
  "credit_card": "信用卡",
  "handling_charge": "手续费",
  "wire_transfer": "电汇",
  "custom_amount": "自定义金额",
  "payment_methodList": ['信用卡','账户余额','Paypal','System','电汇','Payoneer'],
  "business_typeList": ['退款','账户充值','人工信贷','Affiliate Commission','订单','账户提款'],
  "statusList": ['进行中','完成','失败'],
  "max_withdraw": "最大退款额",
  "withdrawal_fee": "退款手续费",
  "paypal_fee": "Paypal手续费",
  "transfer_paypal": "转账到Paypal",
  "member": "会员",
  "privilege": "L1会员特权",
  "authorized_store": "授权商店",
  "bill_type": "账单类型",
  "product_information": "产品信息",
  "L1": "L1会员",
  "L2": "L2会员",
  "unlimited_users": "用户数量不限",
  "active_plan": "当前会员等级",
  "upgraded": "已升级",
  "subscribe_now": "立即申请",
  "bannerList1": [
    {
      title:'会员价-10%优惠',
      desc:'',
    },{
      title:'免费15天仓库存储',
      desc:'',
    },{
      title:'$80 优惠券',
      desc:'',
    },{
      title:'无限制运费报价',
      desc:'',
    },{
      title:'全天候客户服务',
      desc:'',
    },{
      title:'优先处理和运输',
      desc:'',
    },{
      title:'$100 优惠券',
      desc:'',
    },{
      title:'会员价-15%优惠',
      desc:'',
    }
  ],
  "bannerList2": [
    {
      title:'会员价-15%优惠',
      desc:'',
    },{
      title:'免费30天仓库存储',
      desc:'',
    },{
      title:'$80 优惠券',
      desc:'',
    },{
      title:'无限制运费报价',
      desc:'',
    },{
      title:'全天候客户服务',
      desc:'',
    },{
      title:'优先处理和运输',
      desc:'',
    },{
      title:'$100 优惠券',
      desc:'',
    }
  ],
  "apply": "申请",
  "official_email": "公司的官方电子邮件地址",
  "business_license": "上传公司的营业执照",
  "select_file": "选择文件",
  "fill_one": "填写一个即可提交",
  "exporting_files": "导出文档",
  "all": "全部",
  "upload": "上传",
  "received": "接收",
  "all_order": "全部订单",
  "default": "默认",
  "profile": "个人资料",
  "account": "账号",
  "modify_email": "修改邮箱",
  "verify_email": "验证邮箱",
  "add_email": "添加邮箱",
  "first_name": "姓",
  "last_name": "名",
  "language": "语言",
  "timezone": "时区",
  "enterprise_validation": "企业认证",
  "verify_business": "验证业务",
  "verifying": "审核中",
  "verify_success": "验证成功",
  "verify_failed": "审核失败，重新验证",
  "enter_verify": "输入并验证电子邮件",
  "change": "修改",
  "change_password": "修改密码",
  "old_password": "旧密码",
  "new_password": "新密码",
  "confirm_password": "确认密码",
  "shipping_address": "送货地址",
  "add_address": "新增地址",
  "change_address": "修改地址",
  "remove": "删除",
  "tel": "电话",
  "no_address": "送货地址为空",
  "tips": "提示",
  "confirm_remove": "确定要删除这条地址吗?",
  "add": "新增",
  "edit": "修改",
  "a_address":"地址",
  "company": "公司名称",
  "recipient": "收件人姓名",
  "country": "国家",
  "state": "省份",
  "city": "城市",
  "address1": "街道名称",
  "address2": "门牌号",
  "etc": "单元号，门牌号",
  "postal_code": "邮政编码",
  "phone": "电话号码",
  "save": "保存",
  "free_member": "免费升级L1会员",
  "login": "登录",
  "email_login": "邮箱登录",
  "account_login": "账号登录",
  "password": "密码",
  "password_tips": "6-18位任意字符",
  "remember": "记住账号",
  "forget": "忘记密码？",
  "login_google": "谷歌账号登录",
  "have_account": "还没有账号?",
  "signup": "注册",
  "create_account": "创建账号",
}