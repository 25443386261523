export default {
  Balance:"账单",
  Coupons:"优惠卷",
  searchState:[
    "运输状态",
    "待入仓",
    "已入仓",
    "待发货",
    "运输中",
    "已到港",
    "已提货",
    "已装船",
    "已交付",
  ],
  searchWStatus:[
    "货物状态",
    "待入仓库",
    "已入仓",
    "取消"
  ],
  manager:"客服",
  time_in:"在库时间",
  cargoState:[
    "货物信息",
    "目的地",
    "货物状态",
    "日期",
    "订单号."
  ],
  order_no:"订单号.",
  track_no:"物流单号.",
  track:"查询",
  Date:"日期",
  cargo_info:"货物信息",
  list_title:"在库货物列表",
  list_track:"物流列表",
  list_header:[
    "入库单号",
    "照片",
    "品名",
    "供货商",
    "库存时间",
    "PO/FBA(编号)",
    "箱数",
    "仓库地址",
    "货物状态",
    "到货清单编号",
    "费用",
    "总重量",
    "总体积",
    "创建时间",
    "类型",
    "备注"
  ],
  estimated_time:"预计发货时间",
  place_no:"订单/物流单号",
  place_ware:"入库单号",
  place_state:"货物状态",
}